import React, { useState, useEffect } from 'react';
import './classes/CheckoutPage.css';
import { configData } from "../config";
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loading from './Loading';

const CheckoutPage = () => {
 
  
  const [formData, setFormData] = useState({
    email: '',
    country: '',
    state: '',
    firstName: '',
    lastName: '',
    address: '',
    addressOptional: '',
    zipCode: '',
    city: '',
    region: '',
    mobile: '',
	  shipping: '',
    coupon: '',		   
  });
  
  const navigate = useNavigate();
  const [countrieslist, setCountriesList] = useState({});
  const [stateslist, setStatesList] = useState({});
  const [shipmentlist, setShipmentList] = useState({});
  const [showShippingOptions, setShowShippingOptions] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [cart, setCart] = useState([]);
  const [total, setOrderTotal] = useState();
  const [totalwithshipping, setOrderTotalWithShipping] = useState();
  const [shippingamount, setShippingAmount] = useState();
  const [producttypes, setProductTypes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [discounts, setDiscounts] = useState({
      discounts:[],
      applied_coupons:[],
  });
  
  const [couponerror, setCouponError] = useState();
  const [loading, setLoading] = useState(false);
  const app_url = configData.APP_URL;

  useEffect(() => {
   
    apply400discount();
    retrieveCountriesList();
    retrieveCartProducts();
    
    retrieveLoggedinUserInfo();
    retrieveOrderProductTypes();
    
    if(localStorage.getItem('uid')) {
      retrieveShippingInfo();
    } else {
    const sessionValues = JSON.parse(localStorage.getItem('shippingfields'));
    if(sessionValues) {
      retrieveStatesList(sessionValues.country);
      setFormData(prevState => ({
        ...prevState,
        email: sessionValues.email,
        country: sessionValues.country,
        firstName: sessionValues.first_name,
        lastName: sessionValues.last_name,
        address: sessionValues.street_address,
        zipCode:  sessionValues.postal_code,
        city: sessionValues.city,
        mobile: sessionValues.primary_phone,
        }));
    } else {
      retrieveShippingInfo();
    }
  }
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
 }, [])

  if(producttypes) {
    

    if(!producttypes.includes("1") && !producttypes.includes("9")  && !producttypes.includes("4")) {
      navigate("/smww/checkout/cart");
    } else {
     
    }
  }
  function validateEmail (email) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  async function apply400discount() {
    const order_id = localStorage.getItem('order_id');
    const response = await fetch(
      app_url+'smwwcommercepayment/smww400discount?order_id='+order_id,
      {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
   )
   const responsedata = await response.json();
  
  } 
 async function retrieveCountriesList() {
  const countrieslistresponse = await fetch(
    app_url+'smwwcommercecheckout/getcommercecountrieslist',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const countrieslistdata = await countrieslistresponse.json();
  
  const countrieslist = {};
  Object.keys(countrieslistdata.data.result).forEach(function(key) {
    
    countrieslist[key] = countrieslistdata.data.result[key];
  
  });
 
  setCountriesList(countrieslist);
  
}
async function retrieveStatesList(value) {
  const stateslistresponse = await fetch(
    app_url+'smwwcommercecheckout/getcommercestateslist?country='+value,
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const stateslistdata = await stateslistresponse.json();
  
  const stateslist = {};
  Object.keys(stateslistdata.data.result).forEach(function(key) {
    
    stateslist[key] = stateslistdata.data.result[key];
  
  });
 
  setStatesList(stateslist);
  
}
async function retrieveOrderProductTypes() {
  const order_id = localStorage.getItem('order_id');
  const orderProductTypes = await fetch(
    app_url+'smwwcommercecheckout/getorderproducttypes?order_id='+order_id,
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const orderProductTypesdata = await orderProductTypes.json();
  setProductTypes(orderProductTypesdata.data.result);
  
  
}
async function retrieveCartProducts() {
  const order_id = localStorage.getItem('order_id');
  if( order_id) {
  const response = await fetch(
    app_url+'smwwcommercecheckout/getorderdetails?order_id='+order_id,
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const responsedata = await response.json();
 
  setCart(responsedata.data.products);
  setOrderTotal(responsedata.data.order_total_without_discount);
  setShippingAmount(responsedata.data.shipping_amount);
  setDiscounts(prevState => ({
    ...prevState,
    discounts: responsedata.data.discounts,
    applied_coupons: responsedata.data.coupon_applied,
    }));
    
  setOrderTotalWithShipping(responsedata.data.order_total);
}
}
async function retrieveShippingInfo() {
  const order_id = localStorage.getItem('order_id');
  if( order_id) {
  const response = await fetch(
    app_url+'smwwcommercecheckout/getcustomershippingdetails?order_id='+order_id,
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const responsedata = await response.json();
  const data = responsedata.data.result;
  if(data) {
  retrieveStatesList(data.country);
  setFormData(prevState => ({
  ...prevState,
  email: data.email,
  country: data.country,
  state: data.state,
  firstName: data.firstname,
  lastName: data.lastname,
  address: data.address,
  addressOptional: data.address1,
  zipCode: data.zipcode,
  city: data.city,
  region: data.region,
  mobile: data.phone,
  }));
}
}
}
async function retrieveLoggedinUserInfo() {
  const uid = localStorage.getItem('uid');
  if(uid) {
    const response = await fetch(
      app_url+'smwwcommercecheckout/getuserdetails?uid='+uid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    const data = responsedata.data.result;
    if(data) {
    retrieveStatesList(data.country);
    setFormData(prevState => ({
    ...prevState,
    email: data.email,
    country: data.country,
    state: data.state,
    firstName: data.firstname,
    lastName: data.lastname,
    address: data.address,
    addressOptional: data.address1,
    zipCode: data.zipcode,
    city: data.city,
    region: data.region,
    mobile: data.phone,
    }));
  }
  }
}
async function retrieveShippingOptions(country,state,zip) {
  const postData = {
    country: country,
    state: state,
    zip: zip,
    order_id: localStorage.getItem('order_id')
    }
 const response = await fetch(app_url+'smwwcommercecheckout/getshippingchargelist', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        if(data.data.msg === "Success") {
          setShipmentList(data.data.result);
          setShowShippingOptions(true);
        } else {
          alert("Please enter a correct shipping address.");
          return false;
        }
}
 const validateForm = () => {
    let isValid = true;

    if (!formData.email) {
      window.alert('Email is required.');
      isValid = false;
      return false;
    }
    if (!validateEmail(formData.email)) {
      window.alert('Please Enter Valid Email Address.');
      return false;
    }
    if (!formData.country) {
      window.alert('Country is required.');
      isValid = false;
      return false;
    }
    if (!formData.state) {
      window.alert('State is required.');
      isValid = false;
      return false;
    }
    if (!formData.firstName.trim()) {
      window.alert('First Name is required.');
      isValid = false;
      return false;
    }
    if (!formData.lastName.trim()) {
      window.alert('Last Name is required.');
      isValid = false;
      return false;
    }
    if (!formData.address.trim()) {
      window.alert('Address is required.');
      isValid = false;
      return false;
    }
    if (!formData.zipCode.trim()) {
      window.alert('Zip Code is required.');
      isValid = false;
      return false;
    }
    if (!formData.city.trim()) {
      window.alert('City is required.');
      isValid = false;
      return false;
    }
    if (!formData.mobile.trim()) {
      window.alert('Mobile number is required.');
      isValid = false;
      return false;
    }
    if (!formData.shipping) {
      window.alert('Please select the shipping service.');
      isValid = false;
      return false;
    }

    return isValid;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if(name === 'country') {
      retrieveStatesList(value);
    }
  };
  const handleOnBlur = async (e) => {
    const { name, value } = e.target;
   
    if(name === 'zipCode') {
      if (!formData.country) {
        window.alert('Country is required.');
        return false;
      }
      if(formData.country == "US") {
        if (!formData.state) {
          window.alert('State is required.');
          return false;
        }
        
        const postData = {
          country: formData.country,
          state: formData.state,
          zip: formData.zipCode
          }
          const response = await fetch(app_url+'smwwcommercecheckout/validatezipcode', {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
              'Content-Type': 'application/json',
            },
            });
            const data = await response.json();
            if(data.data.result === "Not Valid") {
              window.alert('Invalid ZipCode.');
              return false;
            }
      }
      if (!formData.zipCode) {
        window.alert('Zip Code is required.');
        return false;
      }
      retrieveShippingOptions(formData.country,formData.state,value);
    }
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      
      const postData = {
        formData: formData,
        order_id: localStorage.getItem('order_id')
        }
      const response = await fetch(app_url+'smwwcommercecheckout/putshippinginfo', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        if(data.data.msg === "Success") {
          retrieveCartProducts();
          navigate("/smww/checkout/cart");
        }
    }
    // Handle form submission logic here
  };
  const handleAddCoupon = async event => {
    setLoading(true); // Start loading
    if (!formData.coupon) {
      window.alert('Please enter the coupon.');
      return false;
    }
 
    const postData = {
      coupon: formData.coupon,
      order_id: localStorage.getItem('order_id')
    }
    const response = await fetch(app_url + 'smwwcommercecheckout/addcoupon', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (data.data.result.error_msg) {
      setCouponError(data.data.result.error_msg);
    }
    if (data.data.result.success_msg) {
      setCouponError('');
      setFormData(prevState => ({
        ...prevState,
        coupon: ''
      }));
      retrieveCartProducts();
    }
    setLoading(false); // End loading
  };
  

  const handleRemoveCoupon = async (couponname) => {
    setLoading(true);
    const postData = {
      coupon: couponname,
      order_id: localStorage.getItem('order_id')
      }
      const response = await fetch(app_url+'smwwcommercecheckout/removecoupon', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        if(data.data.msg === "Success") {
          apply400discount();
          await retrieveCartProducts();
        }
        setLoading(false);
  };

  const handleShippingOptionsClick = () => {
    if (!formData.country) {
      window.alert('Country is required.');
      return false;
    }
    if(formData.country == "US") {
      if (!formData.state) {
        window.alert('State is required.');
        return false;
      }
    }
    if (!formData.zipCode) {
      window.alert('Zip Code is required.');
      return false;
    }
    retrieveShippingOptions(formData.country,formData.state,formData.zipCode);
  };
  
   return (
    <>
    {isLoading ? (
        <Loading />
      ) : (
    <div className='checkout-container'>
      <Helmet><title>SMWW Checkout</title></Helmet>
      <div className="checkout-page">
	 
        <div className='mob-container-ch'>
          <div className="shipping-info">
            <div>
              <h3 className="checkout-title">
                If you already have an account with us, please login now.
                <Link to="/login"><button className="ch-login-button">Login</button></Link>
              </h3>
            </div>
            <form id="shipping-form">
              <h2>SHIPPING INFORMATION</h2>
              {!localStorage.getItem('uid') &&
              <input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleChange} required  />
              }
              <select name="country" value={formData.country} onChange={handleChange} required>
                <option value="">Select Country</option>
                {Object.keys(countrieslist).map(item => {
                  return <option key={countrieslist[item].sortname} value={countrieslist[item].sortname}>{countrieslist[item].name}</option>
                })}
              </select>
              <select name="state" value={formData.state} onChange={handleChange} required>
               <option value="">Select State</option>
                {Object.keys(stateslist).map(state => {
                 return <option key={stateslist[state].id} value={stateslist[state].id}>{stateslist[state].name}</option>
              })}
              </select>
              <div className="ch-inline-inputs">
                <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
              </div>
              <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} required />
              <input type="text" name="addressOptional" placeholder="Address (Optional)" value={formData.addressOptional} onChange={handleChange} />
              <div className="ch-inline-inputs">
                <input type="text" name="zipCode" placeholder="Zip Code" value={formData.zipCode} onChange={handleChange} onBlur={handleOnBlur} required />
                <input type="text" name="city" placeholder="City" value={formData.city} onChange={handleChange} required />
                
              </div>
              <div className="ch-inline-inputs">
                <input type="text" name="region" placeholder="Region (Optional)" value={formData.region} onChange={handleChange}/>
                <input type="tel" name="mobile" placeholder="Mobile No." value={formData.mobile} onChange={handleChange} required />
                
              </div>


              {showAlert && (
                  <div className="alert">
                    <span className="closebtn" onClick={() => setShowAlert(false)}>&times;</span>
                    {alertMessage}
                  </div>
              )}
            </form>

          </div>

          
          <div className="shipping-options-box">
                <button type="button" className="get-shipping-options" onClick={handleShippingOptionsClick}>Get Shipping Options</button>
                <h3>Shipping Options</h3>
                {showShippingOptions && (
                  <>
                    <p>*Course materials are shipped the next business day.</p>
                    <p>**Apparel items are shipped within 7 to 14 days.</p>
                    <table className="shipping-options-table">
                      <thead>
                        <tr>
                          <th>Delivery Method</th>
                          <th>Estimated Arrival</th>
                          <th>Shipping Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                      {shipmentlist.map((item, index) => (
                        <tr>
                          <td>
                            <input 
                              type="radio" 
                              name="shipping" 
                              value={item.radio_value}
                              onChange={handleChange} 
                              required 
                            /> {item.title}
                          </td>
                          <td>{item.delivery_time}</td>
                          <td>{item.amount}</td>
                        </tr>
                         ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
			  
          <button form="shipping-form" type="submit" className="next-button1" onClick={handleSubmit}>Next</button>
        </div>
	   

        <div className="checkout-page-order-summary">
          <h2>YOUR ORDER</h2>
          <div className="ch-order-details">
            <div>
              <div className="order-items">
                <span>Product Items ({cart.length})</span>
                <span>${total}</span>
              </div>
              <hr className='checkout-hr' />
              {discounts.discounts.length > 0 &&
              <div>
              {discounts.discounts.map((item, index) => (
                 <>
                {item.amount > 0 &&
                 <><div className="order-shipping"><span>{item.name}</span><span>${item.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</span></div><hr className='payment-hr' /></>
                }
                 </>
              ))}
             </div>
             }
              <div className="order-shipping">
                <span>Shipping</span>
                <span>${shippingamount}</span>
              </div>

              <hr className='checkout-hr' />

              <div className="ch-order-total">
                <span className='ch-total'>TOTAL</span>
                <span className='ch-total-amount'>${totalwithshipping >0 && (totalwithshipping.toLocaleString(undefined, {minimumFractionDigits: 2}))}</span>
              </div>
              <button type="button" className="next-button2" onClick={handleSubmit}>Next</button>
            </div>
            <div className='coupon-container-dotted'>
              <div className="coupon-container">
                <input type="text" name="coupon" placeholder="Coupon" value={formData.coupon} onChange={handleChange}/>
                <button className="ch-apply-coupon apply-coupon" onClick={handleAddCoupon}>Apply</button>
              </div>
              {loading && (
                <div>
                  <img src={`${process.env.PUBLIC_URL}/images/ajax-loader.gif`} alt="loader" className='loader' />
                </div>
              )}
              {couponerror && <span className="error-message">{couponerror}</span>}
            </div>
            {discounts.applied_coupons.length > 0 && (
              <div>
                <h2>Applied Coupon</h2>
                <div className="coupon-details">
                {discounts.applied_coupons.map((item, index) => (
                   
                    <><p>{item.name}</p><p>{item.offer_type ==  1 ? item.unit_amount+'%' : '$'+item.unit_amount}</p>
                    <p><a className="coupon-remove" onClick={() => handleRemoveCoupon(item.name)}>Remove</a></p><br /></>
                ))}
                </div>
              </div>
            )}

            <div>
              <h2>PRODUCT DETAILS</h2>
              {cart.map((item, index) => (
                <div className="product-details" key={index}>
                  <div className="details-row">
                    <p>{item.name}</p>
                    <p>${item.calculated_amount}</p>
                  </div>
                  {(item.classroom && item.classroom != 0) &&
                  <p>Classroom Start Date:{item.classroom}</p>
                  }
                </div>
              ))}
            </div>

            
            <div className="order-note">
              <p>*Discounts are applied during checkout.</p>
              <hr className='checkout-hr' />
              <p>**All prices are in USD.</p>											 	
              <hr className='checkout-hr' />									   
             <p className='ch-entering'>***Entering your contact information during checkout will put you on SMWW's newsletter and SMS list which you can unsubscribe from at any time. 
                <a href='https://www.sportsmanagementworldwide.com/privacy'>Click</a> here to view our Privacy Policy.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}</>
  );
};

export default CheckoutPage;
