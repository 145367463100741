import React,{ useState, useEffect, useRef } from 'react';
import './classes/CartTooltip.css';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { configData } from "../config";
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";


const CartTooltip = ({ cartItems, total, onClose, onRemoveItem }) => {
  const app_url = configData.APP_URL;
 
  const navigate = useNavigate();
 
  const handleRemove = async (index,sku,paytime) => {
    if (window.confirm('Do you want to remove this from your cart?')) {
    const postData = {
      line_item_id: index,
      order_id: localStorage.getItem('order_id'),
      sku : sku,
      paytime: paytime
      }
    const response = await fetch(app_url+'smwwcommercecart/removecartitem', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
     if(data.data.result === "removed") {
      
      const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
      root.render(<MinicartLength length={data.data.line_items.length} />);
      document.getElementById('minicartdetails').style.display = "none";
      navigate("/smww/cart", {state: data.data.msg});
      
     }
    } 
  };
  
  return (
    <div className="cart-tooltip">
      <div className="cart-tooltip-header">
        <span className="">
          <i className="fa fa-shopping-cart cart-icon-inside"></i>
          <span class="badge">{cartItems.length}</span>
        </span>
        <span className='tooltip-cart-price'>Total: ${total.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
      </div>
      <div className="cart-tooltip-content">
        {cartItems.length > 0 ? (
          cartItems.map((item, index) => (
            <div key={index} className="cart-tooltip-item">
              <span className='cart-tooltip-course '>{item.name}</span>
              <span className='tooltip-cart-price'>${item.calculated_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</span>
              <span className='cart-tooltip-delete' onClick={() => handleRemove(item.line_item_id,item.sku,item.paytime)}>
              <FontAwesomeIcon icon={faTrashAlt} />
              </span>
            </div>
          ))

        ) : (
          <div className="cart-tooltip-empty">Your cart is empty</div>
        )}
      </div>
      {cartItems.length > 0 &&
        <div className="cart-tooltip-footer">
          <div className="cart-tooltip-buttons">
          <Link to="/smww/cart">
          <button className="view-cart-button">View Cart</button>
          </Link>
          <Link to="/smww/checkout">
          <button className="checkout-button">Checkout</button>
          </Link>
        </div>
      </div>
      }
    </div>
  );
};

export default CartTooltip;
