import React, { useState, useEffect} from 'react';
import { configData } from "../config";
import { useNavigate, useSearchParams } from 'react-router-dom';


function ConferencePay() {
    const[searchParams,setSearchParams] = useSearchParams();
    const order_id = localStorage.getItem('order_id');
    const lineitems = searchParams.get("lineitems");
    const quantity  = searchParams.get("quantity");
    const app_url = configData.APP_URL;
    const navigate = useNavigate();

    useEffect(()=>{
        addtocart(order_id,lineitems,quantity);
    },[])

    async function addtocart(order_id,lineitems,quantity) {
        const selectedOption = {[lineitems]: quantity};
        const postData = {
         lineitems: selectedOption,
         country:'',
          order_id: order_id,
        }
        const response = await fetch(app_url+'smwwcommercepay/addtocart', {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
              'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if(data.data.result === "order_created") {
            localStorage.setItem('order_id', data.data.order_id);
            localStorage.setItem('drupal_orderid', data.data.drupal_orderid);
            navigate("/smww/cart");
        } else {
               
        }
    }
}
export default ConferencePay;