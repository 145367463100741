import React from 'react';
import { configData } from "../config";
import './classes/Loading.css';

function Loading() {
    
    const domain_url = configData.DOMAIN_URL;
  return (
    <div className="loading">
      <img 
                            src={domain_url+'/images/loader.gif'} 
                            
                        />
    </div>
  );
}

export default Loading;