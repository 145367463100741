import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configData } from "./config";


const root = ReactDOM.createRoot(document.getElementById('root'));
const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
const url = new URL('', window.location.href);
if(url.pathname === '/') {
  window.location.href = drupal_domain_url;
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
