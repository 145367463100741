import React from 'react';
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { configData } from "../config";
import './classes/CreditCardChange.css';

const CancelledSubscriptionCreditCardChange = () => {

  const {subscription_id} = useParams();
  const[userOrderDetails,setUserOrderDetails] = useState({});
  const[error,setError] = useState({});
  const[success,setSuccess] = useState({});
  const [formData, setFormData] = useState({
    ownerName:'',
    cardNumber:'',
    code:'',
    expMonth:'01',
    expYear:'',
    subscriptionId: subscription_id
  })
  const uid = localStorage.getItem('uid');
  const app_url = configData.APP_URL;

  useEffect(() => {
   
    retrieveSubscriptionOrderDetails();
    
  },[]);

  const generateYearOptions = () => {
    const arr = [];
  
    
    const startYear = new Date().getFullYear();
  
    for (let i = startYear; i <= startYear+20; i++) {
      arr.push(<option value={i}>{i}</option>);
    }
  
    return arr;
  };

  const generateMonthOptions = () => {
    const arr = [];
  
    
    const startMonth = 1;
  
    for (let i = startMonth; i <= 12; i++) {
      if(i < 10) i = '0'+i;
      else i = i;
      arr.push(<option value={i}>{i}</option>);
    }
  
    return arr;
  };

  async function retrieveSubscriptionOrderDetails() {
    if(subscription_id) {
    const response = await fetch(
      app_url+'authnetsubscription/getchangecarddetails?subscription_id='+subscription_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.msg === "Success") {
    
      setUserOrderDetails(responsedata.data.result);
    
    } else {
      setError(responsedata.data.result);
    }
    
   
  }
  }
  const validateForm = (data) => {
    const errors = [];
    console.log(data);
    if (!data.ownerName.trim()) {
      
      errors.push("Please fill in name.");
    }
    if (!data.cardNumber.trim()) {
      errors.push("Please fill in credit number.");
    }
    if (!data.code) {
      errors.push("Please fill in security code.");
    }
    return errors;
  }
  const handleChange = event => {
    const { name, value} = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleSubmit = async event => {
    event.preventDefault();
    const newErrors = validateForm(formData);
    setError(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const postData = formData; 
      const response = await fetch(app_url+'authnetsubscription/cancelledsubscriptionchangecreditcard', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      if(data.data.msg === "Success") {
    
        setSuccess(data.data.result);
        setFormData({
          ownerName:'',
          cardNumber:'',
          code:'',
          expMonth:'01',
          expYear:'',
          });
      } else {
        setError(data.data.result);
      }
    }
  
  };

  return (
    <div className="credit-card-change-container">
      <div className="cchange-content">
      
      {error.length > 0 && (
          <div className="set-message-error">
           {error.map((item, index) => {
           return <>{item}<br /></>
          
        })}  
          </div>
        )}
       
         {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
        <div className="cc-header-container">
        <h2>Subscription</h2>
        <a href={"/user/"+userOrderDetails.drupal_uid+"/my_orders"}>← Back</a>
      </div>
        <table className="cchange-table cchange-table-bordered cchange-table-striped">
          <thead>
            <tr>
              <th width="10%">Order #</th>
              <th width="20%">Product</th>
              <th width="12%">Next Billing Date</th>
              <th width="10%">Amount</th>
              <th width="10%">Card</th>
              <th width="10%">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{userOrderDetails.order_id}</td>
              <td>{userOrderDetails.product_title}</td>
              <td>{userOrderDetails.next_billing_date}</td>
              <td>${userOrderDetails.subscription_amount}</td>
              <td>{userOrderDetails.card_num}</td>
              <td>{userOrderDetails.sub_status}</td>
            </tr>
          </tbody>
        </table>

        <h2>Change Credit Card</h2>
        <form className="credit-card-form">
          <div className="cchange-form-group">
            <label>Name (as it appears on your card) *</label>
            <input className='cc-edit-owner' name="ownerName" type="text" onChange={handleChange}/>
          </div>
          <div className="cchange-form-group">
            <label>Card number (no dashes or spaces) *</label>
            <input className='cc-edit-number' name="cardNumber" type="text" maxlength="19" onChange={handleChange}/>
          </div>
          <div className="cchange-form-group">
            <label>Expiration *</label>
            <div className="cchange-expiration-date">
              <select className='edit-exp-month' name="expMonth" onChange={handleChange}>
              {generateMonthOptions()}
              </select>
          
              <select className='edit-exp-year' name="expYear" onChange={handleChange}>
              {generateYearOptions()}
                
              </select>
            </div>
          </div>
          <div className="cchange-form-group">
            <label>Security code (3 on the back, Amex 4 on the front) *</label>
            <input className='edit-exp-month' name="code" type="text" onChange={handleChange}/>
          </div>
         
          <div className="cchange-form-actions">
            <button className='cchange-submit' type="submit" onClick={handleSubmit}>Change</button>
            <a href={"/user/"+userOrderDetails.drupal_uid+"/my_orders"}><button className='cchange-maybelater' type="button">Maybe Later!</button></a>
          </div><br/>
          <div>*The user's payment method will be charged when clicking the "change" button.</div>
        </form>
      </div>
      <div className="cchange-quick-links">
        <h3>Quick Links</h3>
        <ul>
          <li><Link to="/dashboard">Dashboard Home</Link></li>
          <li><Link to="/manage-profile">Manage My Profile</Link></li>
          <li><Link to="/account">My Account</Link></li>
          <li><Link to="/people">People</Link></li>
          <li><Link to="/add-application">Add Application</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default CancelledSubscriptionCreditCardChange;
