import React from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect} from 'react';
import './classes/EditProfile.css';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { configData } from "../config";


const EditProfile = (uid) => {
  uid = uid.uid;
  const [formData, setFormData] = useState({
    field_street: '',
    field_street_2: '',
    field_city: '',
    field_state: '',
    field_postal_code: '',
    field_country: '',
    field_linkedin_url: '',
    field_phone: '',
    field_secondary_phone: '',
    field_province_region: '',
    field_sports_recom: '',
    field_referrer: '',
    field_team_refer: '',
    field_gender: '',
    field_highest_grade: '',
    field_college: '',
    field_degree: '',
    field_current_job: '',
    field_major: '',
    field_military: '',
    field_military_id: '',
    field_been_convicted: '',
    field_felony: '',
    field_is_for_credit: '',
    field_team_organization: '',
    field_favteam: '',
    field_elaborate: '',
    field_high_school: '',
    field_hs_location: '',
    field_hs_grad_year: '',
    field_college_grad_date: '',
    field_birthday: '',
    field_reg_step: '',
    field_hear_about_doc_talk: '',
    field_university: '',
    current_pass: '',
    mail: '',
    password: '',
    confirmPassword: '',
    picture: null,
    uid:uid
  });
  const[error,setError] = useState({});
  const[success,setSuccess] = useState({});
  const [statelist, setStateList] = useState({});
  const [countrieslist, setCountriesList] = useState({});
  const [file, setFile] = useState({});
  const domain_url = configData.DOMAIN_URL;
  const app_url = configData.APP_URL;
  const loggedin_uid = localStorage.getItem('uid');
  const [showPasswordSuggestions, setShowPasswordSuggestions] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');

  useEffect(() => {
    retrieveUserDetails();
    retrieveCountriesList();
    retrieveStateList();
  },[]);
  async function retrieveUserDetails() {
    if(uid) {
    const response = await fetch(
      app_url+'editprofile/getusereditdetails?uid='+uid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.msg === "Success") {
      if(responsedata.data.result) {
        Object.keys(responsedata.data.result).forEach(function(key) {
          
          setFormData(prevState => ({
            ...prevState,
            [key]: responsedata.data.result[key]
          }));
        
        });
      
    }
    }
  }
  }
  async function retrieveStateList() {
    const statelistresponse = await fetch(app_url + 'smwwstates/getstatelist', {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const statelistdata = await statelistresponse.json();
    const statelist = {};
    Object.keys(statelistdata.data.result).forEach(function(key) {
      statelist[key] = statelistdata.data.result[key];
    });
    setStateList(statelist);
   
  }

  async function retrieveCountriesList() {
    const countrieslistresponse = await fetch(app_url + 'smwwcountries/getcountrieslist', {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const countrieslistdata = await countrieslistresponse.json();
    const countrieslist = {};
    Object.keys(countrieslistdata.data.result).forEach(function(key) {
      countrieslist[key] = countrieslistdata.data.result[key];
    });
    setCountriesList(countrieslist);
    
  }
  const validateForm = (data) => {
    const errors = [];
    
    if (!formData.mail.trim()) {
      
      errors.push("E-mail address field is required.");
    }
    if (formData.pass) {
        if(!formData.current_pass) {
          errors.push("Please fill in Current Password.");
        }
        if(!formData.confirmPassword) {
          errors.push("Please fill in Confirm Password.");
        }
    }
    if(formData.field_linkedin_url.trim() !== '') {
      var linkedinurl = formData.field_linkedin_url.toLowerCase();
      if (linkedinurl.includes("linkedin.com/") === false) {
       errors.push('Please enter a valid LinkedIn URL');
        
      }
     }
  
    return errors;
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === 'password') {
      setPasswordStrength(calculatePasswordStrength(value));
    }
  };

  const calculatePasswordStrength = (password) => {
    if (password.length < 6) return 'Weak';
    if (password.length >= 6 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /\d/.test(password) && /[^A-Za-z0-9]/.test(password)) {
      return 'Strong';
    }
    return 'Fair';
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFormData((prevData) => ({
      ...prevData,
      picture: e.target.files[0]
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const newErrors = validateForm(formData);
    setError(newErrors);
   
    if (Object.keys(newErrors).length === 0) {
      const postData = new FormData();
      postData.append('file', file);
      postData.append('formData', JSON.stringify(formData));
      const response = await fetch(app_url+'editprofile/putusereditdetails', {
      method: 'POST',
      body: postData,
     
      });
      const data = await response.json();
      if(data.data.msg === "Success") {
        retrieveUserDetails();
        alert("Your Details are Updated");
        setSuccess(data.data.result);
        window.scrollTo(0, 0);
      } else {
        setError(data.data.result);
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  
  };

  return (
    <div className="edit-profile-container">
       {error.length > 0 && (
          <div className="set-message-error">
           {error.map((item, index) => {
           return <>{item}<br /></>
          
        })}  
          </div>
        )}
       {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
        {loggedin_uid && !Number.isNaN(loggedin_uid) ? (
      <form onSubmit={handleSubmit}>
        <div className="edit-profile-row">
          <label>Street Address</label>
          <input
            type="text"
            name="field_street"
            value={formData.field_street}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Address (cont.)</label>
          <input
            type="text"
            name="field_street_2"
            value={formData.field_street_2}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>City/Town</label>
          <input
            type="text"
            name="field_city"
            value={formData.field_city}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>State/Province</label>
          <select
            name="field_state"
            value={formData.field_state}
            onChange={handleChange}
          >
            <option value="">Select State</option>
            {Object.keys(statelist).map(state => {
                      return <option key={statelist[state].name} value={statelist[state].name}>{statelist[state].name}</option>;
                    })}
            {/* Add state options */}
          </select>
        </div>
        <div className="edit-profile-row">
          <label>Zip/Postal Code</label>
          <input
            type="text"
            name="field_postal_code"
            value={formData.field_postal_code}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Country</label>
        
          <select name="field_country" value={formData.field_country} onChange={handleChange}>
                <option value="">Select Country</option>
                {Object.keys(countrieslist).map(item => {
                  return <option key={countrieslist[item].label} value={countrieslist[item].label}>{countrieslist[item].label}</option>
                })}
              </select>
           
        
        </div>
        <div className="edit-profile-row">
          <label>LinkedIn URL</label>
          <textarea
            name="field_linkedin_url"
            value={formData.field_linkedin_url}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Primary Phone</label>
          <input
            type="text"
            name="field_phone"
            value={formData.field_phone}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Secondary Phone</label>
          <input
            type="text"
            name="field_secondary_phone"
            value={formData.field_secondary_phone}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Province</label>
          <input
            type="text"
            name="field_province_region"
            value={formData.field_province_region}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>How did you hear about us?</label>
          <input
            type='text'
            name="field_sports_recom"
            value={formData.field_sports_recom}
            onChange={handleChange}
          />
        </div>

        <div className="edit-profile-row">
          <label>Referrer</label>
          <select
            name="field_referrer"
            value={formData.field_referrer}
            onChange={handleChange}
          >
            <option value="">- None -</option>
            <option value="Baseball America">Baseball America</option>
            <option value="DegreesinSports.com">DegreesinSports.com</option>
            <option value="Dr. Lashbrook gave me his business card!">Dr. Lashbrook gave me his business card!</option>
            <option value="ESPN">ESPN</option>
            <option value="Fox Sports Radio">Fox Sports Radio</option>
            <option value="Hockey News">Hockey News</option>
            <option value="My professor">My professor</option>
            <option value="NASSM">NASSM</option>
            <option value="NCAA News">NCAA News</option>
            <option value="Other">Other</option>
            <option value="Racingjunk.com">Racingjunk.com</option>
            <option value="Rah Rah Rugby">Rah Rah Rugby</option>
            <option value="Search Engine">Search Engine</option>
            <option value="Sherdog.com">Sherdog.com</option>
            <option value="SLAM magazine">SLAM magazine</option>
            <option value="SMWW Alumni told me about it">SMWW Alumni told me about it</option>
            <option value="SMWW Faculty">SMWW Faculty</option>
            <option value="WorkinSports.com">WorkinSports.com</option>
        
          </select>
        </div>

        <div className="edit-profile-row">
          <label>Name of person who referred you:</label>
          <input
            type="text"
            name="field_team_refer"
            value={formData.field_team_refer}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
            <label>Gender</label>
            <div className="edit-profile-radio-group">
                <label>
                <input
                    type="radio"
                    name="field_gender"
                    value="N/A"
                    checked={formData.field_gender === 'N/A'}
                    onChange={handleChange}
                /> N/A
                </label>
                <label>
                <input
                    type="radio"
                    name="field_gender"
                    value="Male"
                    checked={formData.field_gender === 'Male'}
                    onChange={handleChange}
                /> Male
                </label>
                <label>
                <input
                    type="radio"
                    name="field_gender"
                    value="Female"
                    checked={formData.field_gender === 'Female'}
                    onChange={handleChange}
                /> Female
                </label>
                <label>
                <input
                    type="radio"
                    name="field_gender"
                    value="Non-binary"
                    checked={formData.field_gender === 'Non-binary'}
                    onChange={handleChange}
                /> Non-binary
                </label>
                <label>
                <input
                    type="radio"
                    name="field_gender"
                    value="Prefer not to answer"
                    checked={formData.field_gender === 'Prefer not to answer'}
                    onChange={handleChange}
                /> Prefer not to answer
                </label>
            </div>
            </div>

        <div className="edit-profile-row">
          <label>Education Level Completed</label>
          <input
            type="text"
            name="field_highest_grade"
            value={formData.field_highest_grade}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Other University</label>
          <input
            type="text"
            name="field_college"
            value={formData.field_college}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Major / Degree / Course Completed</label>
          <input
            type="text"
            name="field_degree"
            value={formData.field_degree}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Current Job</label>
          <input
            type="text"
            name="field_current_job" 
            value={formData.field_current_job}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Current Major</label>
          <input
            type="text"
            name="field_major"
            value={formData.field_major}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Are You a Member of the Military?</label>
          <div className="edit-profile-radio-group">
          <label>
            <input
              type="radio"
              name="field_military"
              value="N/A"
              checked={formData.field_military === 'N/A'}
              onChange={handleChange}
            /> N/A
            </label>
            <label>
            <input
              type="radio"
              name="field_military"
              value="Yes"
              checked={formData.field_military === 'Yes'}
              onChange={handleChange}
            /> Yes
            </label>
            <label>
            <input
              type="radio"
              name="field_military"
              value="No"
              checked={formData.field_military === 'No'}
              onChange={handleChange}
            /> No
            </label>
          </div>
        </div>

          <div className="edit-profile-row">
            <label>If Yes, please provide your military ID number</label>
            <input
              type="text"
              name="field_military_id"
              value={formData.field_military_id}
              onChange={handleChange}
            />
          </div>
          
        <div className="edit-profile-row">
          <label>Have you ever been convicted of a felony?</label>
          <div className="edit-profile-radio-group">
            <label>
            <input
              type="radio"
              name="field_been_convicted"
              value="N/A"
              checked={formData.field_been_convicted === 'N/A'}
              onChange={handleChange}
            /> N/A
            </label>
            <label>
            <input
              type="radio"
              name="field_been_convicted"
              value="Yes"
              checked={formData.field_been_convicted === 'Yes'}
              onChange={handleChange}
            /> Yes
            </label>
            <label>
            <input
              type="radio"
              name="field_been_convicted"
              value="No"
              checked={formData.field_been_convicted === 'No'}
              onChange={handleChange}
            /> No
            </label>
          </div>
        </div>
          <div className="edit-profile-row">
            <label>If yes, please explain</label>
            <input
              type='text'
              name="field_felony"
              value={formData.field_felony}
              onChange={handleChange}
            />
          </div>
        <div className="edit-profile-row">
          <label>Do you plan to take this course toward college credit?</label>
          <div className="edit-profile-radio-group">
            <label>
            <input
              type="radio"
               name="field_is_for_credit"
              value="N/A"
              checked={formData.field_is_for_credit === 'N/A'}
              onChange={handleChange}
            /> N/A
            </label>
            <label>
            <input
              type="radio"
              name="field_is_for_credit"
              value="Yes"
              checked={formData.field_is_for_credit === 'Yes'}
              onChange={handleChange}
            /> Yes
            </label>
            <label>
            <input
              type="radio"
              name="field_is_for_credit"
              value="No"
              checked={formData.field_is_for_credit === 'No'}
              onChange={handleChange}
            /> No
            </label>
          </div>
        </div>
        <div className="edit-profile-row">
          <label>What team/organization are you with?</label>
          <input
            type="text"
            name="field_team_organization"
            value={formData.field_team_organization}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>If you could work for your favorite sports team, who would it be?</label>
          <input
            type="text"
            name="field_favteam"
            value={formData.field_favteam}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Tell Us Your Story:</label>
          <textarea
            name="field_elaborate"
            value={formData.field_elaborate}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>High School</label>
          <input
            type="text"
            name="field_high_school"
            value={formData.field_high_school}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>High School Location</label>
          <input
            type="text"
            name="field_hs_location"
            value={formData.field_hs_location}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>High School Graduation Year</label>
          <input
            type="text"
            name="field_hs_grad_year"
            value={formData.field_hs_grad_year}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>College Graduation Date</label>
          <input
            type="text"
            name="field_college_grad_date"
            value={formData.field_college_grad_date}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row date-picker">
          <label>Birthday</label>
          <DatePicker name="field_birthday"
          selected={formData.field_birthday}
         
          onChange={handleChange}
        />
        <span className='edit-profile-desc'>E.g.,    08/03/2024</span>
        </div>

        {/* <div className="edit-profile-row">
          <label>App Register Step</label>
          <select
            name="field_reg_step"
            value={formData.field_reg_step}
            onChange={handleChange}
            >
                <option value="1"> 1 </option>
                <option value="2"> 2 </option>
                <option value="3"> 3 </option>
            </select>
        </div> */}
        <div className="edit-profile-row">
          <label>How did you hear about the DOC Talk?</label>
          <select
            name="field_hear_about_doc_talk"
            value={formData.field_hear_about_doc_talk}
            onChange={handleChange}
          >
            <option value="">- None -</option>
            <option value="Facebook">Facebook</option>
            <option value="Family/Friend Referral">Family/Friend Referral</option>
            <option value="Instagram">Instagram</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="SMWW Mentor">SMWW Mentor</option>
            <option value="SMWW Newsletter">SMWW Newsletter</option>
            <option value="SMWW Website">SMWW Website</option>
            <option value="Twitter">Twitter</option>
          </select>
        </div>
        <div className="edit-profile-row">
          <label>University</label>
          <input
            type="text"
            name="field_university"
            value={formData.field_university}
            onChange={handleChange}
          />
        </div>
        <div className="edit-profile-row">
          <label>Current Password</label>
          <input
            className='edit-profile-password-box'
            type="password"
            name="current_pass"
            value={formData.current_pass}
            onChange={handleChange}
          />
          <span className='edit-profile-desc'>Enter your current password to change the <i>E-mail address</i> or <i>Password.</i> <a>Request new password.</a> </span>
        </div>
        <div className="edit-profile-row">
          <label>Email address</label>
          <input
            type="email"
            name="mail"
            value={formData.mail}
            onChange={handleChange}
            required
          />
          <span className='edit-profile-desc'>
          A valid e-mail address. All e-mails from the system will be sent to this address.
         The e-mail address is not made public and will only be used if you wish to receive a new password or
          wish to receive certain news or notifications by e-mail.
          </span>
        </div>

        <div className="edit-profile-row">
          <label>Password</label>
          <div className="edit-profile-password-container">
            <input
            className='edit-profile-password-box'
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              onFocus={() => setShowPasswordSuggestions(true)}
              onBlur={() => setShowPasswordSuggestions(false)}
            />
            <div className="edit-profile-password-strength">
              <span>Password strength: {passwordStrength}</span>
              <div className={`strength-bar ${passwordStrength.toLowerCase()}`}></div>
            </div>
          </div>
        </div>
        <div className="edit-profile-row">
          <label>Confirm password</label>
          <input
          className='edit-profile-password-box'
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            onFocus={() => setShowPasswordSuggestions(true)}
            onBlur={() => setShowPasswordSuggestions(false)}
          />
          {showPasswordSuggestions && (
            <span className="edit-profile-password-suggn">
              <p>To make your password stronger:</p>
              <ul>
                <li>Make it at least 6 characters</li>
                <li>Add lowercase letters</li>
                <li>Add uppercase letters</li>
                <li>Add numbers</li>
                <li>Add punctuation</li>
              </ul>
            </span>
          )}
          <span className="edit-profile-desc">
            To change the current user password, enter the new password in both fields.
          </span>
        </div>

        <div className="edit-profile-row">
          <label>Picture</label>
          {formData.picture ? (  
            <><img  src={formData.picture} width="100px" height="100px" alt = "user"/></>
          ):(
            <><img  src={domain_url+"pictures/notavailable.jpg"} width="100px" height="100px" alt = "user"/></>
          )}
          
          <br />
          <input
            type="file"
            name="picture"
            onChange={handleFileChange}
          />
          <span className='edit-profile-desc'>Your virtual face or picture. Pictures larger than 1024x1024 pixels will be scaled down.</span>
        </div>
        <div className="edit-profile-row">
          <button className='edit-profile-submit-button' type="submit">Save</button>
        </div>
      </form>
        ) : (
       <><p>You are not authorized</p></>   
        )}
    </div>
  );
};

export default EditProfile;
